import type {
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { isOptionalFilterSportFamily } from 'web/src/modules/sportline/guards';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { isSportEventsSportsDateTimeFilterChanged } from 'web/src/modules/sportline/submodules/sports/utils';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

import BaseSportsPageNavigationService from './BaseSportsPageNavigationService';

export default class DefaultSportsPageNavigationService extends BaseSportsPageNavigationService {
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const superResult = await super.prefetch(router, to, from);
    if (superResult !== undefined) {
      return superResult as RouteLocationRaw;
    }

    const dateTimeFilter = this.getDateTimeFilter(to);
    const isFilterChanged = this.isFilterChanged(dateTimeFilter);
    const navigationStore = useRootNavigationStore();
    const sportlineSportsStore = useSportlineSportsStore();

    const { setPersistLocation } = useSportlinePersistLocation();
    setPersistLocation('sports', sportlineSportsStore.pageIdentifier);

    if (sportlineSportsStore.isLoaded && !isFilterChanged) {
      sportlineSportsStore.syncBackgroundRequests();
      navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
      return;
    }

    navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_PREMATCH_EVENTS);
    void sportlineSportsStore.initialRequests({ silent: false, dateTimeFilter }).then(() => {
      navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
    });
    await sportlineSportsStore.awaitStateIsLoaded();

    const { sportFamily } = getSportlinePageNavigationParameters(to);
    const selectedSport = useSportlineSportsStore().actualSportElement;

    // LEONWEB-3298 redirects for alias
    if (selectedSport && sportFamily && selectedSport.sport.navigationParameters.urlName !== sportFamily) {
      void router.push301({
        name: router.currentRoute.value.name || '',
        params: {
          family: selectedSport.sport.navigationParameters.urlName,
        },
      });
    }
  }

  checkAllowedFilter(navigationParameters: SportEventsPageIdentifier): RouteLocationRaw | undefined {
    const { sportFamily } = navigationParameters;
    const { allowedSportFamily, customFilters } = useSportlineSportsStore();

    return !isOptionalFilterSportFamily(sportFamily, allowedSportFamily, customFilters)
      ? { name: RouteName.SPORTLINE_PREMATCH_EVENTS }
      : undefined;
  }

  isFilterChanged(dateTimeFilter?: SportDateTimeFilter): boolean {
    return !!dateTimeFilter
      && isSportEventsSportsDateTimeFilterChanged(dateTimeFilter, useSportlineSportsStore().sportsListFilter);
  }
}
