<script lang="ts" setup>
import { computed, toRef } from 'vue';

import type { SportlineTabEmits, SportlineTabProps } from 'web/src/modules/sportline/components/SportlineTab/types';
import { defaultListFilter } from 'web/src/modules/sportline/constants';
import SportlineFavoritesTabList from 'web/src/modules/sportline/submodules/favorites/views/default/SportlineFavoritesTabList.vue';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { isSportEventsSportsDateTimeFilterChanged } from 'web/src/modules/sportline/submodules/sports/utils';

import SportsFavoriteRegionsFilter from './SportsFavoriteRegionsFilter.vue';

defineProps<SportlineTabProps>();
const emit = defineEmits<SportlineTabEmits>();

const sportlineSportsStore = useSportlineSportsStore();

const sportsListFilter = toRef(sportlineSportsStore, 'sportsListFilter');
const selectedRegionFamily = toRef(sportlineSportsStore, 'favoriteSelectedRegionFamily');
const favoriteEventsList = toRef(sportlineSportsStore, 'favoriteEventsListForDisplay');
const { setFavoriteSelectedRegionFamily } = sportlineSportsStore;

const isSomeFilterSelected = computed(() => isSportEventsSportsDateTimeFilterChanged(
  sportsListFilter.value,
  defaultListFilter,
));
</script>

<template>
  <div v-auto-id="'SportsFavoritesTab'">
    <SportsFavoriteRegionsFilter
      :class="$style['sports-favorites-region-filter']"
      :selected-region-family="selectedRegionFamily"
      @select-region-family="setFavoriteSelectedRegionFamily"
    />

    <SportlineFavoritesTabList
      :is-active="isActive"
      :favorite-events-list="favoriteEventsList"
      :selected-region-family="selectedRegionFamily"
      :is-some-filter-selected="isSomeFilterSelected"
      default-expand-league-key="favorite-all-league"
      default-expand-sport-key="favorite-all-sport"
      @redirect-from-tab="emit('redirect-from-tab')"
      @reset-filter="emit('reset-filter')"
    />
  </div>
</template>

<style module lang="scss">
.sports-favorites-region-filter {
  padding-bottom: 16px;
}
</style>
