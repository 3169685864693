import type { Ref } from 'vue';
import { ref, toRef } from 'vue';

import type { FilterTransitionElementRef } from 'web/src/modules/sportline/components/FilterTransition/types';
import type { FilterTransitionDuration } from 'web/src/modules/sportline/types/settings';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';

interface UseSportsListFilterTransitionComposable {
  listTransitionRef: Ref<Optional<FilterTransitionElementRef>>;
  activeTransitionId: Ref<Maybe<string>>;
  lastShownSportFamily: Ref<Maybe<string>>;
  isFilterTransitionFadeEffectEnabled: Ref<boolean>;
  filterTransitionDuration: Ref<FilterTransitionDuration>;
  onChangeTransitionId(id: Maybe<string>): void;
}

export function useSportsListFilterTransition(): UseSportsListFilterTransitionComposable {
  const sportlineSettingsStore = useSportlineSettingsStore();
  const isFilterTransitionFadeEffectEnabled = toRef(sportlineSettingsStore, 'isFilterTransitionFadeEffectEnabled');
  const filterTransitionDuration = toRef(sportlineSettingsStore, 'filterTransitionDuration');

  const sportlineSportsStore = useSportlineSportsStore();
  const selectedSportFilter = toRef(sportlineSportsStore, 'actualListFilter');

  const listTransitionRef = ref<Optional<FilterTransitionElementRef>>();

  const activeTransitionId = ref<Maybe<string>>(null);
  const lastShownSportFamily = ref<Maybe<string>>(null);

  function onChangeTransitionId(id: Maybe<string>): void {
    activeTransitionId.value = id;
    lastShownSportFamily.value = id
      // we will use transitionId for display active tab
      ? null
      // display last tab before transition changed
      : (selectedSportFilter.value.sportFamily ?? null);
  }

  return {
    listTransitionRef,
    activeTransitionId,
    lastShownSportFamily,
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    onChangeTransitionId,
  };
}
