import type {
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';

import type { SportFamily } from 'web/src/modules/sportline/enums';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { SportsPageNavigationService } from 'web/src/modules/sportline/submodules/sports/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { isOptionalFilterSportFamily } from 'web/src/modules/sportline/guards';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

export default class BaseSportsPageNavigationService implements SportsPageNavigationService {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    _?: RouteLocationNormalized,
  ): AbstractPrefetchResult {
    const navigationParameters = getSportlinePageNavigationParameters(to);
    const { sportFamily } = navigationParameters;
    const family = sportFamily?.toLowerCase() as SportFamily;
    const checkFilterResult = this.checkAllowedFilter(navigationParameters);

    if (isSportFamilyEquals(family, CustomFilter.ZeroMarginDeprecated)) {
      return router.resolve301location(resolveSportsPageLink({ urlName: CustomFilter.ZeroMargin }));
    }

    if (checkFilterResult !== undefined) {
      return checkFilterResult;
    }

    useSportlineSportsStore().setPageIdentifier(navigationParameters);
    useSportlineSportsStore().setSelectedSportFamily(family || null);
  }

  checkAllowedFilter(navigationParameters: SportEventsPageIdentifier): RouteLocationRaw | undefined {
    const { sportFamily } = navigationParameters;
    const { allowedSportFamily, customFilters } = useSportlineSportsStore();

    return !isOptionalFilterSportFamily(sportFamily, allowedSportFamily, customFilters)
      ? { name: RouteName.ERROR_NOT_FOUND_404 }
      : undefined;
  }

  getDateTimeFilter(to: RouteLocationNormalized): Optional<SportDateTimeFilter> {
    const navigationStore = useSportlineNavigationStore();

    const savedFilter = navigationStore.sportlineNavigationBetweenPagesFilter;
    navigationStore.setSportlineNavigationBetweenPagesFilter(null);
    return to.meta.dateFilter as Maybe<SportDateTimeFilter>
      ?? savedFilter
      ?? undefined;
  }
}
