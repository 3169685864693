import type { Ref, WatchStopHandle } from 'vue';
import { ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';

import type { DateTimeFilterReference } from 'web/src/modules/sportline/components/date-time-filter/types';
import type { SportFilterKickoffPeriod, SportlineType } from 'web/src/modules/sportline/enums';
import type { DateTimeRange, SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { useSportsListDateFilter } from 'web/src/modules/sportline/submodules/sports-list/components/composables';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';

interface UseSportsDateTimeFilterComposable {
  dateTimeFilterRef: Ref<Optional<DateTimeFilterReference>>;
  isSportFiltersEnabled: Ref<boolean>;
  datetimeActiveKickoffPeriod: Ref<SportFilterKickoffPeriod>;
  datetimeActiveSportlineType: Ref<Optional<SportlineType>>;
  datetimeCustomFrom: Ref<Optional<number>>;
  datetimeCustomTo: Ref<Optional<number>>;
  onDateTimeFilterSelect(filter: Maybe<SportEventsSportsDateTimeFilter>): Promise<void>;
  onDateTimeFilterSelectCustomRange(range: DateTimeRange): Promise<void>;
}

export function useSportsDateTimeFilter(): UseSportsDateTimeFilterComposable {
  const router = useRouter();

  const sportlineSettingsStore = useSportlineSettingsStore();
  const isSportFiltersEnabled = toRef(sportlineSettingsStore, 'isSportFiltersEnabled');

  const sportlineSportsStore = useSportlineSportsStore();
  const selectedSportFilter = toRef(sportlineSportsStore, 'actualListFilter');
  const sportsListFilter = toRef(sportlineSportsStore, 'sportsListFilter');
  const isReadyToDisplayContent = toRef(sportlineSportsStore, 'isLoaded');
  const { reloadListForFilter } = sportlineSportsStore;

  const dateTimeFilterRef = ref<Optional<DateTimeFilterReference>>();

  const {
    dateTimeFilterCustomFrom: datetimeCustomFrom,
    dateTimeFilterCustomTo: datetimeCustomTo,
    dateTimeFilterActiveKickoffPeriod: datetimeActiveKickoffPeriod,
    dateTimeFilterActiveSportlineType: datetimeActiveSportlineType,
  } = useSportsListDateFilter({ dateTimeFilter: sportsListFilter });

  function isSportsPage(): boolean {
    return router.currentRoute.value.name === RouteName.SPORTLINE_PREMATCH_EVENTS;
  }

  /**
   * Make redirect ot common sports page from today and etc
   */
  function redirectToSportsPage(): void {
    if (isSportsPage()) { return; }
    const urlName = selectedSportFilter.value.sportFamily;
    void router.push(resolveSportsPageLink({ urlName }));
  }

  async function onDateTimeFilterSelect(filter: Maybe<SportEventsSportsDateTimeFilter>): Promise<void> {
    redirectToSportsPage();
    await reloadListForFilter(filter);
  }

  async function onDateTimeFilterSelectCustomRange(range: DateTimeRange): Promise<void> {
    redirectToSportsPage();
    await reloadListForFilter(range);
  }

  function dateTimeFilterSlideToActive(): void {
    if (!isReadyToDisplayContent.value) { return; }
    dateTimeFilterRef.value?.slideToActive();
  }

  let stopWatchRouteHandle: Optional<WatchStopHandle>;

  function startWatchRoute(): void {
    stopWatchRouteHandle = stopWatchRouteHandle ?? watch(router.currentRoute, () => {
      dateTimeFilterSlideToActive();
    }, { deep: true, immediate: true });
  }

  function stopWatchRoute(): void {
    stopWatchRouteHandle?.();
    stopWatchRouteHandle = undefined;
  }

  onComponentActivated(startWatchRoute);
  onComponentDeactivated(stopWatchRoute);

  return {
    dateTimeFilterRef,
    isSportFiltersEnabled,
    datetimeActiveKickoffPeriod,
    datetimeActiveSportlineType,
    datetimeCustomFrom,
    datetimeCustomTo,
    onDateTimeFilterSelect,
    onDateTimeFilterSelectCustomRange,
  };
}
