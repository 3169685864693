import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SportFamily } from 'web/src/modules/sportline/enums';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';

interface UseShownSportFamilyProps {
  lastShownSportFamily: Ref<Maybe<string>>;
}

interface UseShownSportFamilyComposable {
  selectedSportFamily: Ref<Optional<SportFamily | string>>;
  shownSportFamily: Ref<Optional<SportFamily | string>>;
}

export function useShownSportFamily(
  props: UseShownSportFamilyProps,
): UseShownSportFamilyComposable {
  const { lastShownSportFamily } = props;

  const sportlineSportsStore = useSportlineSportsStore();
  const selectedSportFilter = toRef(sportlineSportsStore, 'actualListFilter');

  const selectedSportFamily = computed<Optional<SportFamily | string>>(() => selectedSportFilter.value.sportFamily);
  // show last tab for case when active tab changed but transition is not start yet
  // for avoid extra mount for tab component
  const shownSportFamily = computed<Optional<SportFamily | string>>(() => (lastShownSportFamily.value ?? selectedSportFamily.value));

  return {
    selectedSportFamily,
    shownSportFamily,
  };
}
