<script lang="ts" setup>
import { ref, toRef } from 'vue';

import type {
  SportlineFilterRef,
  SportlineFilterSlots,
} from 'web/src/modules/sportline/components/navigation-filter/types';
import type { ListFilter } from 'web/src/modules/sportline/submodules/sports/types';
import type { ListFilterOptions } from 'web/src/modules/sportline/types/list';
import SportlineFilter from 'web/src/modules/sportline/components/navigation-filter/default/SportlineFilter.vue';

import { useSportlineSportsFilter } from './useSportlineSportsFilter';

interface SportlineSportsFilterProps {
  options: ListFilterOptions;
  filter: ListFilter;
  isFavoritesTabEnabled?: boolean;
  isZeroMarginTabEnabled?: boolean;
}

interface SportlineSportsFilterEmits {
  (e: 'change-filter', filter: ListFilter): void;
}

const props = defineProps<SportlineSportsFilterProps>();
const emit = defineEmits<SportlineSportsFilterEmits>();
defineSlots<SportlineFilterSlots>();

const {
  items,
} = useSportlineSportsFilter({
  options: toRef(props, 'options'),
  isFavoritesTabEnabled: toRef(props, 'isFavoritesTabEnabled', false),
  isZeroMarginTabEnabled: toRef(props, 'isZeroMarginTabEnabled', false),
  filter: toRef(props, 'filter'),
}, {
  changeFilter(filter: ListFilter): void {
    emit('change-filter', filter);
  },
});

const baseFilter = ref<Optional<SportlineFilterRef>>();

async function slideToActive(smooth?: boolean): Promise<void> {
  await baseFilter.value?.slideToActive(smooth);
}

defineExpose<SportlineFilterRef>({ slideToActive });
</script>

<template>
  <SportlineFilter v-auto-id="'SportlineSportsFilter'"
    ref="baseFilter"
    :items="items"
  >
    <template #afterItem="{ item }">
      <slot
        name="afterItem"
        :item="item"
      />
    </template>
  </SportlineFilter>
</template>
