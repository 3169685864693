import type {
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';

import { usePrerenderSportsPageNavigation } from 'web/src/modules/sportline/submodules/sports/composables/redirects';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';

import BaseSportsPageNavigationService from './BaseSportsPageNavigationService';

export default class PrerenderSportsPageNavigationService extends BaseSportsPageNavigationService {
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const superResult = await super.prefetch(router, to, from);

    if (superResult !== undefined) {
      return (superResult as RouteLocationRaw);
    }

    const { setRouteContentLoaded } = useRootNavigationStore();
    const {
      initialRequests,
      awaitStateIsLoaded,
    } = useSportlineSportsStore();
    const {
      proceedRedirects,
    } = usePrerenderSportsPageNavigation({ router, to, from });

    const dateTimeFilter = this.getDateTimeFilter(to);

    void initialRequests({ silent: false, dateTimeFilter }).then(() => {
      setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
    });

    await awaitStateIsLoaded();
    return proceedRedirects();
  }
}
