import type { Ref } from 'vue';
import { computed } from 'vue';

import { isString } from '@leon-hub/guards';
import { IconName, IconSize } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { SportlineFilterItem } from 'web/src/modules/sportline/components/navigation-filter/types';
import type {
  SportlineNavigationCommonIcon,
  SportlineNavigationSportIcon,
} from 'web/src/modules/sportline/components/navigation-indicator/types';
import type { SportFamily } from 'web/src/modules/sportline/enums';
import type { ListFilter } from 'web/src/modules/sportline/submodules/sports/types';
import type { Sport } from 'web/src/modules/sportline/types';
import type { ListFilterOptions } from 'web/src/modules/sportline/types/list';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import {
  SportlineNavigationItemCustomStyle,
  SportlineNavigationItemType,
} from 'web/src/modules/sportline/enums/navigation';
import { isSportFamilyEquals, isSportIdEquals } from 'web/src/modules/sportline/utils';

import { resolveOptionLocation } from './utils';

interface UseSportlineSportsFilterProps {
  options: Ref<ListFilterOptions>;
  isFavoritesTabEnabled: Ref<boolean>;
  isZeroMarginTabEnabled: Ref<boolean>;
  filter: Ref<ListFilter>;
}

interface UseSportlineSportsFilterEmits {
  changeFilter(filter: ListFilter): void;
}

interface UseSportlineSportsFilterComposable {
  items: Ref<SportlineFilterItem[]>;
}

/**
 * @TODO review input options
 */
export function useSportlineSportsFilter(
  props: UseSportlineSportsFilterProps,
  emit: UseSportlineSportsFilterEmits,
): UseSportlineSportsFilterComposable {
  const {
    options,
    isFavoritesTabEnabled,
    isZeroMarginTabEnabled,
    filter,
  } = props;

  const { $translate } = useI18n();

  function isSportIdSelected(sport: string | Sport): boolean {
    return isSportIdEquals(sport, filter.value?.sportId)
      || isSportFamilyEquals(sport, filter.value?.sportFamily);
  }

  function emitChangeFilter(sport: string | Sport | null): void {
    const currentFilter = filter.value || {};
    const newFilter = (!sport || isString(sport))
      ? { ...currentFilter, sportId: sport || undefined, sportFamily: undefined }
      : { ...currentFilter, sportId: sport.id, sportFamily: sport.navigationParameters.urlName as SportFamily };

    emit.changeFilter(newFilter);
  }

  const favoriteFilterItem = computed<Maybe<SportlineFilterItem>>(() => {
    if (!isFavoritesTabEnabled.value) {
      return null;
    }

    const isActive = isSportIdSelected(CustomFilter.Favorites);
    const to = resolveOptionLocation(CustomFilter.Favorites);
    const icon: SportlineNavigationCommonIcon = {
      type: SportlineNavigationItemType.Icon,
      props: { name: IconName.STAR_ALT },
    };

    return {
      key: CustomFilter.Favorites,
      icon,
      title: $translate('WEB2_SPORT_EVENTS_FILTER_FAVORITE').value,
      isActive,
      customFilter: CustomFilter.Favorites,
      to,
      onClick: () => emitChangeFilter(CustomFilter.Favorites),
      dataTest: CustomFilter.Favorites,
      transitionId: CustomFilter.Favorites,
    };
  });
  const zeroMarginFilterItem = computed<Maybe<SportlineFilterItem>>(() => {
    if (!isZeroMarginTabEnabled.value) {
      return null;
    }

    const isActive = isSportIdSelected(CustomFilter.ZeroMargin);
    const to = resolveOptionLocation(CustomFilter.ZeroMargin);
    const icon: SportlineNavigationCommonIcon = {
      type: SportlineNavigationItemType.Icon,
      props: { name: IconName.SPORTLINE_HIGHEST_ODDS_TABS },
    };

    return {
      key: CustomFilter.ZeroMargin,
      icon,
      title: $translate('WEB2_SPORT_EVENTS_FILTER_HIGHEST_ODDS').value,
      isActive,
      customFilter: CustomFilter.ZeroMargin,
      customStyle: SportlineNavigationItemCustomStyle.HighestOdds,
      to,
      onClick: () => emitChangeFilter(CustomFilter.ZeroMargin),
      dataTest: CustomFilter.ZeroMargin,
      transitionId: CustomFilter.ZeroMargin,
    };
  });

  const items = computed<SportlineFilterItem[]>(() => {
    const list: SportlineFilterItem[] = [];
    if (favoriteFilterItem.value) {
      list.push(favoriteFilterItem.value);
    }
    if (zeroMarginFilterItem.value) {
      list.push(zeroMarginFilterItem.value);
    }

    for (const item of options.value.sports) {
      const isActive = isSportIdSelected(item.sport);
      const to = resolveOptionLocation(item);
      const icon: SportlineNavigationSportIcon = {
        type: SportlineNavigationItemType.SportIcon,
        props: { sportName: item.sport.representation.family, size: IconSize.SIZE_16 },
      };

      const filterItem: SportlineFilterItem = {
        key: item.sport.id,
        icon,
        title: item.sport.name,
        isActive,
        to,
        onClick: () => emitChangeFilter(item.sport),
        dataTest: item.sport.name,
        transitionId: item.sport.navigationParameters.urlName,
      };

      list.push(filterItem);
    }

    return list;
  });

  return { items };
}
