import type {
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';

import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

interface UsePrerenderSportsPageNavigationProps {
  router: AppVueRouter;
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
}

interface UsePrerenderSportsPageNavigationComposable {
  proceedRedirects(): AbstractPrefetchResult;
}

export function usePrerenderSportsPageNavigation(
  props: UsePrerenderSportsPageNavigationProps,
): UsePrerenderSportsPageNavigationComposable {
  const {
    router,
    to,
  } = props;

  const sportlineSportsStore = useSportlineSportsStore();
  const isZeroMarginEventsTabSelected = toRef(sportlineSportsStore, 'isZeroMarginEventsTabSelected');
  const isZeroMarginEventsTabAvailable = toRef(sportlineSportsStore, 'isZeroMarginEventsTabAvailable');
  const actualSportElement = toRef(sportlineSportsStore, 'actualSportElement');

  async function proceedRedirects(): AsyncAbstractPrefetchResult {
    await Promise.resolve();

    // redirects from zero margin events empty page
    if (isZeroMarginEventsTabSelected.value) {
      if (!isZeroMarginEventsTabAvailable.value) {
        return router.resolve302location({ name: RouteName.HOME });
      }

      // redirects if sport family is correct but have not got sport after load state
    } else if (!actualSportElement.value) {
      const navigationParameters = getSportlinePageNavigationParameters(to);

      // do redirect to bets only if we have a selected sportFamily
      if (navigationParameters.sportFamily) {
        return router.resolve302location({ name: RouteName.SPORTLINE_PREMATCH_EVENTS });
      }
    }
  }

  return { proceedRedirects };
}
