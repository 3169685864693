import type { Ref, WatchStopHandle } from 'vue';
import {
  computed,
  nextTick,
  onBeforeMount,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';

import type { FilterTransitionElementRef } from 'web/src/modules/sportline/components/FilterTransition/types';
import type { SportlineFilterRef } from 'web/src/modules/sportline/components/navigation-filter/types';
import type { SportFamily } from 'web/src/modules/sportline/enums';
import type { ListFilter, SportsListTab } from 'web/src/modules/sportline/submodules/sports/types';
import type { SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import type { ListFilterOptions } from 'web/src/modules/sportline/types/list';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import { useSportlineAnalytics } from 'web/src/modules/sportline/composables/analytics';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { isDifferentSportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/guards';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { sportlinePrematchChildRoutes } from 'web/src/modules/sportline/submodules/sports/constants';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { createSportsListTab } from 'web/src/modules/sportline/submodules/sports/utils';

import type { UseSportsPageCustomTabsAvailable } from './useSportsPageCustomTabs';
import { useShownSportFamily } from './useShownSportFamily';
import { useSportsPageCustomTabs } from './useSportsPageCustomTabs';

interface UseSportsRoutePageProps {
  activeTransitionId?: Ref<Maybe<string>>;
  lastShownSportFamily?: Ref<Maybe<string>>;
  listTransitionRef?: Ref<Optional<FilterTransitionElementRef>>;
}

interface UseSportsRoutePageComposable extends UseSportsPageCustomTabsAvailable {
  listFilterRef: Ref<Optional<SportlineFilterRef>>;
  isReadyToDisplayContent: Ref<boolean>;
  doShowLoadingIndicator: Ref<boolean>;
  isPageEmpty: Ref<boolean>;
  fullTabsList: Ref<SportsListTab[]>;
  selectedSportFilter: Ref<ListFilter>;
  selectedSportFilterOptions: Ref<ListFilterOptions>;
  isChildRoute: Ref<boolean>;
  reloadListForFilter(filter: Maybe<SportEventsSportsDateTimeFilter>): Promise<void>;
  redirectFromTab(): Promise<void>;
  onChangeSelectedSportFilter(filter: ListFilter): void;
}

/**
 * @TODO divide into several parts and use only required parts
 */
export function useSportsRoutePage(
  props?: UseSportsRoutePageProps,
): UseSportsRoutePageComposable {
  const router = useRouter();
  const analytics = useSportlineAnalytics();

  const favoritesEventsStore = useFavoritesEventsStore();

  const activeTransitionId = toRef(props?.activeTransitionId ?? null);
  const lastShownSportFamily = toRef(props?.lastShownSportFamily ?? null);
  const listTransitionRef = toRef(props?.listTransitionRef);

  const sportlineSportsStore = useSportlineSportsStore();
  const selectedSportFilter = toRef(sportlineSportsStore, 'actualListFilter');
  const isReadyToDisplayContent = toRef(sportlineSportsStore, 'isLoaded');
  const doShowLoadingIndicator = toRef(sportlineSportsStore, 'showLoadingIndicator');
  const fullSportsList = toRef(sportlineSportsStore, 'fullSportsList');
  const isZeroMarginEventsListEmpty = toRef(sportlineSportsStore, 'isZeroMarginEventsListEmpty');
  const sportsListFilter = toRef(sportlineSportsStore, 'sportsListFilter');
  const customFilters = toRef(sportlineSportsStore, 'customFilters');
  const {
    reloadListForFilter,
    setBackgroundUpdateEnabled,
    setPageIdentifier,
    resetFilterOnLeavingPage,
  } = sportlineSportsStore;

  const sportlineNavigationStore = useSportlineNavigationStore();

  const sportlineNavigationBetweenPagesFilter = toRef(sportlineNavigationStore, 'sportlineNavigationBetweenPagesFilter');
  const sportlineReloadFiltersLocation = toRef(sportlineNavigationStore, 'sportlineReloadFiltersLocation');
  const {
    setSportlineReloadFiltersLocation,
    setSportlineNavigationBetweenPagesFilter,
  } = sportlineNavigationStore;

  const {
    isZeroMarginEventsTabAvailable,
    isFavoritesTabAvailable,
    hasFavoritesOptimistic,
    favoritesTab,
    zeroMarginEventsTab,
  } = useSportsPageCustomTabs({
    lastShownSportFamily,
    activeTransitionId,
  });
  const {
    selectedSportFamily,
    shownSportFamily,
  } = useShownSportFamily({ lastShownSportFamily });

  const listFilterRef = ref<Optional<SportlineFilterRef>>();

  const selectedSportFilterOptions = computed<ListFilterOptions>(() => ({
    sports: fullSportsList.value.map(({ sport }: SportsTreeSportElement) => ({ sport })),
  }));
  const sportsTabs = computed<SportsListTab[]>(() => fullSportsList.value
    .map(({ sport }: SportsTreeSportElement): SportsListTab => createSportsListTab({
      sport,
      selectedSportFamily: selectedSportFamily.value,
      shownSportFamily: shownSportFamily.value,
      transitionId: activeTransitionId.value,
    })));

  /** The list of all available contents tabs */
  const fullTabsList = computed<SportsListTab[]>(() => {
    const customTabs: SportsListTab[] = [];

    if (favoritesTab.value) {
      customTabs.push(favoritesTab.value);
    }

    if (zeroMarginEventsTab.value) {
      customTabs.push(zeroMarginEventsTab.value);
    }

    return [...customTabs, ...sportsTabs.value].filter((tab) => tab.isShown);
  });

  const isPageEmpty = computed<boolean>(() => (
    isReadyToDisplayContent.value
    && selectedSportFilterOptions.value.sports.length === 0
    && !hasFavoritesOptimistic.value
    && isZeroMarginEventsListEmpty.value
  ));
  const isChildRoute = computed<boolean>(() => {
    const currentName = router.currentRoute.value.name;
    return !!currentName && sportlinePrematchChildRoutes.has(currentName as string);
  });

  function isSportsPage(): boolean {
    return router.currentRoute.value.name === RouteName.SPORTLINE_PREMATCH_EVENTS;
  }

  function listFilterSlideToActive(): void {
    if (!isReadyToDisplayContent.value) {
      return;
    }

    void listFilterRef.value?.slideToActive();
  }

  function onChangeSelectedSportFilter(filter: ListFilter): void {
    const { sportFamily, sportId } = filter;
    let urlName: Optional<SportFamily | string> = '';
    const routeSportFamily = router.currentRoute.value.params.family;

    if (sportFamily) {
      urlName = sportFamily;
    } else if (customFilters.value.includes(sportId as CustomFilter)) {
      urlName = sportId;
    }

    if (sportId === CustomFilter.ZeroMargin) {
      analytics.zeroMarginTabClick();
    }

    if (isSportsPage() && routeSportFamily && routeSportFamily === sportFamily) {
      return;
    }

    if (listTransitionRef.value?.isActive()) {
      // stop navigation for active transition
      return;
    }

    listTransitionRef.value?.enable();
    void router.push302(resolveSportsPageLink({ urlName }), { saveScrollPosition: true });
  }

  async function redirectFromTab(): Promise<void> {
    onChangeSelectedSportFilter({});
    await nextTick();
    listFilterSlideToActive();
  }

  let stopWatchSidebarFiltersHandle: Optional<WatchStopHandle>;

  function startWatchSidebarFilters(): void {
    stopWatchSidebarFiltersHandle = watch(sportlineReloadFiltersLocation, (to) => {
      if (to !== RouteName.SPORTLINE_PREMATCH_EVENTS) {
        return;
      }

      const filter = sportlineNavigationBetweenPagesFilter.value;
      const currentFilter = sportsListFilter.value;
      setSportlineReloadFiltersLocation(null);
      setSportlineNavigationBetweenPagesFilter(null);

      if (!isDifferentSportDateTimeFilter(filter, currentFilter)) {
        return;
      }

      void reloadListForFilter(filter);
    });
  }

  function stopWatchSidebarFilters(): void {
    stopWatchSidebarFiltersHandle?.();
    stopWatchSidebarFiltersHandle = undefined;
  }

  function onBeforeMountPage(): void {
    void favoritesEventsStore.initialRequests();
    listFilterSlideToActive();
  }

  function onActivatePage(): void {
    setBackgroundUpdateEnabled(true);
    startWatchSidebarFilters();
  }

  function onDeactivatePage(): void {
    stopWatchSidebarFilters();
    setBackgroundUpdateEnabled(false);
    // clear event info after leave page (need for leave page watch in store)
    setPageIdentifier({ sportFamily: '' });

    if (!isChildRoute.value) {
      resetFilterOnLeavingPage();
    }
  }

  onBeforeMount(onBeforeMountPage);
  onComponentActivated(onActivatePage);
  onComponentDeactivated(onDeactivatePage);

  return {
    isZeroMarginEventsTabAvailable,
    isFavoritesTabAvailable,
    listFilterRef,
    isReadyToDisplayContent,
    doShowLoadingIndicator,
    isPageEmpty,
    fullTabsList,
    selectedSportFilter,
    selectedSportFilterOptions,
    isChildRoute,
    reloadListForFilter,
    redirectFromTab,
    onChangeSelectedSportFilter,
  };
}
